import * as React from "react";
import styled from "styled-components";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { convertUtf8ToHex } from "@walletconnect/utils";
import { IInternalEvent } from "@walletconnect/types";
import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Modal from "./components/Modal";
import Header from "./components/Header";
import Loader from "./components/Loader";
import { fonts } from "./styles";
import { apiGetAccountAssets, apiGetGasPrices } from "./helpers/api";
import {
  sanitizeHex,
  verifySignature,
  hashTypedDataMessage,
  hashMessage,
} from "./helpers/utilities";
import { convertAmountToRawNumber, convertStringToHex } from "./helpers/bignumber";
import { IAssetData } from "./helpers/types";
import Banner from "./components/Banner";
// import AccountAssets from "./components/AccountAssets";
import { eip712 } from "./helpers/eip712";
import { getChainData } from './helpers/utilities';

const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper as any)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SLanding = styled(Column as any)`
  height: 600px;
`;

const SButtonContainer = styled(Column as any)`
  width: 250px;
  margin: 50px 0;
`;

const SConnectButton = styled(Button as any)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  margin: 12px 0;
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;

// @ts-ignore
const SBalances = styled(SLanding as any)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STable = styled(SContainer as any)`
  flex-direction: column;
  text-align: left;
`;

const SRow = styled.div`
  width: 100%;
  display: flex;
  margin: 6px 0;
`;

const SKey = styled.div`
  width: 30%;
  font-weight: 700;
`;

const SValue = styled.div`
  width: 70%;
  font-family: monospace;
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const STestButton = styled(Button as any)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  max-width: 175px;
  margin: 12px;
`;

interface IAppState {
  connector: WalletConnect | null;
  fetching: boolean;
  connected: boolean;
  chainId: number;
  showModal: boolean;
  pendingRequest: boolean;
  uri: string;
  accounts: string[];
  address: string;
  result: any | null;
  assets: IAssetData[];
  signature: string
}

const INITIAL_STATE: IAppState = {
  connector: null,
  fetching: false,
  connected: false,
  chainId: 1,
  showModal: false,
  pendingRequest: false,
  uri: "",
  accounts: [],
  address: "",
  result: null,
  assets: [],
  signature: ""
};

class App extends React.Component<any, any> {
  public state: IAppState = {
    ...INITIAL_STATE,
  };
  public componentDidMount(){
    window.addEventListener('customevent', this.customEventHandler)
    window.addEventListener('loadedInWebView', this.onLoaded)
    window.addEventListener('onTransactionSigned', this.handleOnSign)
  }
  public connect = async () => {
    // bridge url
    const bridge = "https://bridge.walletconnect.org";

    // create new connector
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    await this.setState({ connector });

    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    }

    // subscribe to events
    await this.subscribeToEvents();
  };
  public connectWithWebView = () => {
    window.location.href = "app://connect"
  }
  public sign = () => {
    window.location.href = "app://sign?data=0xa9059cbb000000000000000000000000ebb897d6e990736a61b7bd0d75b1ff20a605cbf30000000000000000000000000000000000000000000000004563918244f40000&to=0x2AB7E7ae0E4576B310fE06e1051Dac552318A60d&amount=0.01"
  }
  public customEventHandler = (e: CustomEvent) => {
    console.log(e.detail[`data`])
  }
  public onLoaded = (e: CustomEvent) => {
    console.log(e.detail[`data`])
  }
  public handleOnSign = (e: CustomEvent) => {
    const signature = e.detail[`signature`]
    // let publicKey = e.detail[`publicKey`]
    console.log(signature)
    this.setState({signature})
  }
  public subscribeToEvents = () => {
    const { connector } = this.state;

    if (!connector) {
      return;
    }

    connector.on("session_update", async (error, payload) => {
      console.log(`connector.on("session_update")`);

      if (error) {
        throw error;
      }

      const { chainId, accounts } = payload.params[0];
      this.onSessionUpdate(accounts, chainId);
    });

    connector.on("connect", (error, payload) => {
      console.log(`connector.on("connect")`);

      if (error) {
        throw error;
      }

      this.onConnect(payload);
    });

    connector.on("disconnect", (error, payload) => {
      console.log(`connector.on("disconnect")`);

      if (error) {
        throw error;
      }

      this.onDisconnect();
    });

    if (connector.connected) {
      const { chainId, accounts } = connector;
      const address = accounts[0];
      this.setState({
        connected: true,
        chainId,
        accounts,
        address,
      });
      this.onSessionUpdate(accounts, chainId);
    }

    this.setState({ connector });
  };

  public killSession = async () => {
    const { connector } = this.state;
    if (connector) {
      connector.killSession();
    }
    this.resetApp();
  };

  public resetApp = async () => {
    await this.setState({ ...INITIAL_STATE });
  };

  public onConnect = async (payload: IInternalEvent) => {
    const { chainId, accounts } = payload.params[0];
    const address = accounts[0];
    await this.setState({
      connected: true,
      chainId,
      accounts,
      address,
    });
    this.getAccountAssets();
  };

  public onDisconnect = async () => {
    this.resetApp();
  };

  public onSessionUpdate = async (accounts: string[], chainId: number) => {
    const address = accounts[0];
    await this.setState({ chainId, accounts, address });
    await this.getAccountAssets();
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, address, assets });
    } catch (error) {
      console.error(error);
      await this.setState({ fetching: false });
    }
  };

  public toggleModal = () => this.setState({ showModal: !this.state.showModal });

  public testSendTransaction = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // from
    const from = address;

    // to
    const to = address;

    // nonce
    // const _nonce = await apiGetAccountNonce(address, chainId);
    // const nonce = sanitizeHex(convertStringToHex(_nonce));

    // gasPrice
    const gasPrices = await apiGetGasPrices();
    const _gasPrice = gasPrices.slow.price;
    const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    // gasLimit
    const _gasLimit = 21000;
    const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    // value
    const _value = 0;
    const value = sanitizeHex(convertStringToHex(_value));

    // data
    const data = "0x";

    // test transaction
    const tx = {
      from,
      to,
      nonce: 0x0114,
      gasPrice,
      gasLimit,
      value,
      data,
    };

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send transaction
      const result = await connector.sendTransaction(tx);
      const activeChain = chainId ? getChainData(chainId).native_currency.symbol : null;
      // format displayed result
      const formattedResult = {
        method: "eth_sendTransaction",
        txHash: result,
        from: address,
        to: address,
        value: `${_value} ${activeChain}`,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };
  public sendCustomTransaction = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }
    
    // from
    // const from = address;

    // // to
    // const to = address;

    // // nonce
    // // const _nonce = await apiGetAccountNonce(address, chainId);
    // // const nonce = sanitizeHex(convertStringToHex(_nonce));
    
    // // gasPrice
    // const gasPrices = await apiGetGasPrices();
    // const _gasPrice = gasPrices.slow.price;
    // const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    // // gasLimit
    // const _gasLimit = 21000;
    // const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    // // value
    // const _value = 0;
    // const value = sanitizeHex(convertStringToHex(_value));

    // // data
    // const data = "0x";

    // test transaction
    // const tx = {
    //   from,
    //   to,
    //   nonce: 0x0114,
    //   gasPrice,
    //   gasLimit,
    //   value,
    //   data,
    // };
    const addressVal : string = (document.getElementById('address') as HTMLInputElement).value;
    const amount : number = Number((document.getElementById('amount') as HTMLInputElement).value);
    if(addressVal.length === 0){
      alert('Enter address')
      return
    }
    if(amount === 0){
      alert('Enter amount')
      return
    }
    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });
      // send transaction
      const dataObj = {
        data: '',
        amount: -1
      }
      const customRequest = {
        id: 1337,
        jsonrpc: "2.0",
        method: "bnb_sign",
        "params": [
          {
            "account_number": "34",
            "chain_id": "97",
            "data": JSON.stringify(dataObj),
            "memo": "test",
            "msgs": [
              {
                "inputs": [
                  {
                    "address": addressVal,
                    "coins": [
                      {
                        "amount": amount,
                        "denom": "BNB"
                      },
                    ],
                  },
                ],
                "outputs": [
                  {
                    "address": addressVal,
                    "coins": [
                      {
                        "amount": amount,
                        "denom": "BNB",
                      },
                    ],
                  },
                ],
              },
            ],
            "sequence": "31",
            "source": "1",
          }
        ]
      }
      await connector.sendCustomRequest(customRequest);
      // console.log(result)
      const activeChain = chainId ? getChainData(chainId).native_currency.symbol : null;
      // format displayed result
      const formattedResult = {
        method: "bnb_sign",
        // txHash: result,
        from: address,
        to: address,
        value: `${amount} ${activeChain}`,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };
  public confirmTransaction = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }
    
    // from
    // const from = address;

    // // to
    // const to = address;

    // // nonce
    // // const _nonce = await apiGetAccountNonce(address, chainId);
    // // const nonce = sanitizeHex(convertStringToHex(_nonce));
    
    // // gasPrice
    // const gasPrices = await apiGetGasPrices();
    // const _gasPrice = gasPrices.slow.price;
    // const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    // // gasLimit
    // const _gasLimit = 21000;
    // const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    // // value
    // const _value = 0;
    // const value = sanitizeHex(convertStringToHex(_value));

    // // data
    // const data = "0x";

    // test transaction
    // const tx = {
    //   from,
    //   to,
    //   nonce: 0x0114,
    //   gasPrice,
    //   gasLimit,
    //   value,
    //   data,
    // };

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send transaction
      const customRequest = {
        method: "bnb_tx_confirmation",
        "params": [
          {
            "ok": true,
            "error": "Error message (optional)"
          }
        ]
      }
      const result = await connector.sendCustomRequest(customRequest);
      console.log(result)
      const activeChain = chainId ? getChainData(chainId).native_currency.symbol : null;
      // format displayed result
      const formattedResult = {
        method: "bnb_tx_confirmation",
        txHash: result,
        from: address,
        to: address,
        value: `${10} ${activeChain}`,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };
  public testSignTransaction = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // from
    const from = address;

    // to
    const to = address;

    // nonce
    // const _nonce = await apiGetAccountNonce(address, chainId);
    // const nonce = sanitizeHex(convertStringToHex(_nonce));

    // gasPrice
    const gasPrices = await apiGetGasPrices();
    const _gasPrice = gasPrices.slow.price;
    const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    // gasLimit
    const _gasLimit = 21000;
    const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    // value
    const _value = 10;
    const value = sanitizeHex(convertStringToHex(_value));

    // data
    const data = "0x";

    // test transaction
    const tx = {
      from,
      to,
      nonce: 0x0114,
      gasPrice,
      gasLimit,
      value,
      data,
    };

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send transaction
      const result = await connector.signTransaction(tx);
      const activeChain = chainId ? getChainData(chainId).native_currency.symbol : null;
      // format displayed result
      const formattedResult = {
        method: "eth_signTransaction",
        from: address,
        to: address,
        value: `${_value} ${activeChain}`,
        result,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };

  public testLegacySignMessage = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // test message
    const message = `My email is john@doe.com - ${new Date().toUTCString()}`;

    // hash message
    const hash = hashMessage(message);

    // eth_sign params
    const msgParams = [address, hash];

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send message
      const result = await connector.signMessage(msgParams);

      // verify signature
      const valid = await verifySignature(address, result, hash, chainId);

      // format displayed result
      const formattedResult = {
        method: "eth_sign (legacy)",
        address,
        valid,
        result,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };

  public testStandardSignMessage = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // test message
    const message = `My email is john@doe.com - ${new Date().toUTCString()}`;

    // encode message (hex)
    const hexMsg = convertUtf8ToHex(message);

    // eth_sign params
    const msgParams = [address, hexMsg];

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send message
      const result = await connector.signMessage(msgParams);

      // verify signature
      const hash = hashMessage(message);
      const valid = await verifySignature(address, result, hash, chainId);

      // format displayed result
      const formattedResult = {
        method: "eth_sign (standard)",
        address,
        valid,
        result,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };

  public testPersonalSignMessage = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // test message
    const message = `My email is john@doe.com - ${new Date().toUTCString()}`;

    // encode message (hex)
    const hexMsg = convertUtf8ToHex(message);

    // eth_sign params
    const msgParams = [hexMsg, address];

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send message
      const result = await connector.signPersonalMessage(msgParams);

      // verify signature
      const hash = hashMessage(message);
      const valid = await verifySignature(address, result, hash, chainId);

      // format displayed result
      const formattedResult = {
        method: "personal_sign",
        address,
        valid,
        result,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };

  public testSignTypedData = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    const message = JSON.stringify(eip712.example);

    // eth_signTypedData params
    const msgParams = [address, message];

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // sign typed data
      const result = await connector.signTypedData(msgParams);

      // verify signature
      const hash = hashTypedDataMessage(message);
      const valid = await verifySignature(address, result, hash, chainId);

      // format displayed result
      const formattedResult = {
        method: "eth_signTypedData",
        address,
        valid,
        result,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };

  public render = () => {
    const {
      assets,
      address,
      connected,
      chainId,
      fetching,
      showModal,
      pendingRequest,
      result,
    } = this.state;
    return (
      <SLayout>
        <Column maxWidth={1000} spanHeight>
          <Header
            connected={connected}
            address={address}
            chainId={chainId}
            killSession={this.killSession}
          />
          <SContent>
            {!address && !assets.length ? (
              <SLanding center>
                <h3>
                  {`EXS Wallet`}
                  <br />
                  {/* <span>{`v${process.env.REACT_APP_VERSION}`}</span> */}
                </h3>
                <SButtonContainer>
                  <SConnectButton left onClick={this.connect} fetching={fetching}>
                    {"Connect to EXS Wallet"}
                  </SConnectButton>
                  {/* <SConnectButton left onClick={this.connectWithWebView} fetching={fetching}>
                    {"Connect Using WebView"}
                  </SConnectButton>
                  <SConnectButton left onClick={this.sign} fetching={fetching}>
                    {"Sign"}
                  </SConnectButton> */}
                </SButtonContainer>
                <div style={{width: '100%',padding: 10}}>
                    <span style={{width: '100%',wordBreak: 'break-word'}}>{this.state.signature}</span>
                </div>
              </SLanding>
            ) : (
              <SBalances>
                <Banner />
                <h3>Sign Transaction</h3>
                <Column center>
                  <STestButtonContainer>
                    <input id="address" style={{width: '60%',paddingLeft: 10,height: 35,borderWidth: 1,borderRadius: 16,marginBottom: 10}} placeholder="Enter receiver address"/>
                    <input id="amount" style={{width: '60%',paddingLeft: 10,height: 35,borderWidth: 1,borderRadius: 16}} placeholder="Enter amount"/>
                    <STestButton left onClick={this.sendCustomTransaction}>
                      {"Sign"}
                    </STestButton>
                    {/* <STestButton left onClick={this.confirmTransaction}>
                      {"bnb_tx_confirm"}
                    </STestButton>
                    <STestButton left onClick={this.testSignTypedData}>
                      {"eth_signTypedData"}
                    </STestButton>
                    <STestButton left onClick={this.testLegacySignMessage}>
                      {"eth_sign (legacy)"}
                    </STestButton>
                    <STestButton left onClick={this.testStandardSignMessage}>
                      {"eth_sign (standard)"}
                    </STestButton>
                    <STestButton left onClick={this.testPersonalSignMessage}>
                      {"personal_sign"}
                    </STestButton> */}
                  </STestButtonContainer>
                </Column>
                {/* <h3>Balances</h3>
                {!fetching ? (
                  <AccountAssets chainId={chainId} assets={assets} />
                ) : (
                  <Column center>
                    <SContainer>
                      <Loader />
                    </SContainer>
                  </Column>
                )} */}
              </SBalances>
            )}
          </SContent>
        </Column>
        <Modal show={showModal} toggleModal={this.toggleModal}>
          {pendingRequest ? (
            <SModalContainer>
              <SModalTitle>{"Pending Call Request"}</SModalTitle>
              <SContainer>
                <Loader />
                <SModalParagraph>{"Approve or reject request using your wallet"}</SModalParagraph>
              </SContainer>
            </SModalContainer>
          ) : result ? (
            <SModalContainer>
              <SModalTitle>{"Call Request Approved"}</SModalTitle>
              <STable>
                {Object.keys(result).map(key => (
                  <SRow key={key}>
                    <SKey>{key}</SKey>
                    <SValue>{result[key].toString()}</SValue>
                  </SRow>
                ))}
              </STable>
            </SModalContainer>
          ) : (
            <SModalContainer>
              <SModalTitle>{"Call Request Rejected"}</SModalTitle>
            </SModalContainer>
          )}
        </Modal>
      </SLayout>
    );
  };
}

export default App;
